<template>
  <MobileAppSuggestion v-if="isShowPopUpComponent" />
  <main-header />
  <router-view :key="$route.fullPath" />
  <main-footer :socialLinks="socialLinks" />
  <div class="loader" style="background-color: #fff">
    <div class="custom-loader"></div>
  </div>
  <div id="errors"></div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
import MainHeader from "./components/HeaderComponent.vue";
import MainFooter from "./components/FooterComponent.vue";
import MobileAppSuggestion from "./components/MobileAppSuggestion.vue";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {
    MainHeader,
    MainFooter,
    MobileAppSuggestion,
  },
  data() {
    return {
      socialLinks: {},
      lang: "en",
    };
  },
  computed: {
    isShowPopUpComponent() {
      return (
        this.$route.path.includes("digital-store") ||
        this.$route.path.includes("/card/")
      );
    },
  },
  created() {
    this.setLangCookies();
    this.getSiteInfo();
    this.fetchAllCategoriesAndSubCategories();
    this.fetchAlldigitalCategoriesAndSubCategories();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "fetchAllCategoriesAndSubCategories",
      "fetchAlldigitalCategoriesAndSubCategories",
    ]),

    async getSiteInfo() {
      try {
        const response = await axios.get(
          `${API_URL}/settings`,
          {
            headers: {
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          console.log("response =====>", response.data);
          if (response?.data?.data?.title) {
            document.title = response.data.data.title; // Set the site title
          }
          if (response?.data?.data?.description) {
            try {
              let descriptionMetaTag = document.querySelector(
                'meta[name="description"]'
              );
              if (descriptionMetaTag) {
                descriptionMetaTag.setAttribute(
                  "content",
                  response?.data?.data?.description
                );
              } else {
                descriptionMetaTag = document.createElement("meta");
                descriptionMetaTag.setAttribute("name", "description");
                descriptionMetaTag.setAttribute(
                  "content",
                  response?.data?.data?.description
                );
                document.head.appendChild(descriptionMetaTag);
              }
            } catch (err) {
              console.log("errrrr=.>>>>>", err);
            }
          }
          this.socialLinks = {
            facebook_link: response?.data?.data?.facebook_link,
            instagram_link: response?.data?.data?.instagram_link,
            x_link: response?.data?.data?.x_link,
            youtube_link: response?.data?.data?.youtube_link,
          };
        }
      } catch (error) {
        console.error(error);
      }
    },
    setLangCookies() {
      let langCheck = document.cookie.indexOf("lang");

      this.is_cookies = langCheck >= 0 ? true : false;

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      } // to get an cookie by name ##############################

      if (langCheck !== -1) {
        this.lang = getCookie("lang"); // check lang cookie exist ##############################
      }

      if (sessionStorage.getItem("lang"))
        this.lang = sessionStorage.getItem("lang"); // check lang session exist ##############################

      sessionStorage.setItem("lang", this.lang); // set lang session ##############################

      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("lang")) {
        this.lang = searchParams.get("lang");
        document.body.classList.add(searchParams.get("lang")); // add lang class ##############################
      } else {
        document.body.classList.add(this.lang); // add lang class ##############################
      }
    },
  },
};
</script>

<style>
@import "./assets/css/main.css";
#errors {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 1.25rem * 2);
  gap: 1rem;
  z-index: 99999999999999999999;
}
#errors > * {
  width: 100%;
  color: #fff;
  font-size: 1.1rem;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#errors .error {
  background: #e41749;
}
#errors .success {
  background: #12c99b;
}
.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  background: #000000a1 !important;
  backdrop-filter: blur(1px);
  display: none;
}
.custom-loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #ff3100;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: s7 1s infinite steps(8);
}

@keyframes s7 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
