<template>
    <main class="card_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang == 'en' ? 'Cart' : 'عربة المنتجات' }}
            </div>
        </div>
        <div class="container">
            <div class="table_wrapper"  v-if="cart && cart.length > 0">
                <table>
                    <tbody>
                        <tr v-for="product in cart" :key="product.id">
                            <td>
                                <div class="head"
                                    @click="product.product_type == 1 ? this.$router.push(`/product/${product.id}/${replaceSpaces(product.name)}`) : this.$router.push(`/card/${product.id}/${replaceSpaces(product.name)}`)">
                                    <img :src="product.product_type == 1 ? product.img : product.img" :alt="product.name">
                                    <p class="prod-name">
                                        {{ product.name }}
                                        <span class="hint-pop-up" v-if="product && product.name.length > 39">{{ product.name }}</span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div class="price">
                                    <span>{{ cart_data.price }}</span>
                                    <p>{{ product.price_after_discount ? product.price_after_discount.toLocaleString() : product.price.toLocaleString() }} {{ cart_data.egp }}
                                    </p>
                                </div>
                            </td>
                            <td class="qty-td">
                                <div class="qty">
                                    <span>{{cart_data.qty}}</span>
                                    <div class="cart-buttons">
                                        <span @click="
                                        (product.product_type == 1 ? quantities[`product_${product.id}`] : quantities[`card_${product.id}`]) > 1 ?
                                            (product.product_type == 1 ?
                                                addProductToCart(product.id,
                                                    (product.product_type == 1 ? quantities[`product_${product.id}`] : quantities[`card_${product.id}`]) - 1
                                                )
                                            :
                                            addCardToCart(product.id,
                                                (product.product_type == 1 ? quantities[`product_${product.id}`] : quantities[`card_${product.id}`]) - 1
                                            ))
                                        :
                                        ''
                                        ">
                                            <i class="fa fa-minus"></i>
                                        </span>
                                        {{ product.product_type == 1 ? quantities[`product_${product.id}`] :  quantities[`card_${product.id}`] }}
                                        <span @click="
                                            (product.product_type == 1 ? quantities[`product_${product.id}`] : quantities[`card_${product.id}`]) > 0 ?
                                                (product.product_type == 1 ?
                                                    addProductToCart(product.id,
                                                        (product.product_type == 1 ? quantities[`product_${product.id}`] : quantities[`card_${product.id}`]) + 1
                                                    )
                                                    :
                                                    addCardToCart(product.id,
                                                        (product.product_type == 1 ? quantities[`product_${product.id}`] : quantities[`card_${product.id}`]) + 1
                                                    ))
                                                :
                                                ''
                                            ">
                                                <i class="fa-solid fa-plus"></i>
                                            </span>
                                        </div>
                                </div>
                            </td>
                            <td>
                                <div class="total">
                                    <span>{{ cart_data.total }}</span>
                                    <p >{{ product.total_price.toLocaleString() }}</p>
                                </div>
                            </td>
                            <td>
                                <div class="remove">
                                    <button @click="
                                        (product.product_type == 1 ?
                                            deleteProductToCart(product.id)
                                            :
                                            deleteCardToCart(product.id)
                                        )
                                    "><i class="fa fa-close"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="total" v-if="cart && cart.length > 0">
                <div class="head">
                    {{ cart_data.cart_total }}
                </div>
                <div class="bottom">
                    <h4>{{ cart_data.total }} <span>{{total.toLocaleString() }} {{ cart_data.egp }}</span></h4>
                    <div class="slider-container">
                        <div class="points-header"><span>{{ lang == "en" ? 'USE YOUR POINTS':'حول نقاطك الي خصم' }}</span></div>
                        <div class="point-balance-container">
                            <div><span>{{ lang == "en" ? 'Balance':'رصيدك الحالي' }}: {{ current_points }}</span></div>
                            <div><span>{{ lang == "en" ? 'Remaining':'المتبقي' }}: {{ current_points - sliderValue }}</span></div>
                        </div>
                        <div class="point-slider">
                            <VueSlider ref="slider" :tooltip="'always'"
                            :process-style="{backgroundColor: '#0b5177', borderColor: '#0b5177' }"
                            :dot-style="{borderColor: '#0b5177' }"
                            :rail-style="{backgroundColor: '#D1D5DB', borderColor: '#D1D5DB' }"
                            :tooltip-style="{ backgroundColor: '#0b5177', borderColor: '#0b5177' }"
                            height="6px" v-model="sliderValue" :min="0" :interval="tier_increment" :max="max_redeemable_points" :direction="lang == 'en' ? 'ltr': 'rtl'" />
                        </div>
                        <div class="point-info-container">
                            <div><span class="redeem-text">{{ lang == "en" ? `Redeem ${sliderValue} Points`:` تحويل${sliderValue} نقطة الي ${monetary_value_per_point*sliderValue} جنيه` }}</span><span v-if="lang == 'en'" class="egp-text"> = {{monetary_value_per_point*sliderValue}} EGP</span></div>
                        </div>
                    </div>
                    <button @click="goToCheckout()">{{ cart_data.checkout }}</button>
                </div>
            </div>
            <h1 v-if="!cart || cart.length == 0"  style="width:100%;margin: 5rem 0px; text-align: center; color: rgb(113, 113, 113);">{{ cart_data.empty }}</h1>
        </div>
        <div class="hide-content" v-if="showMsgPopUp"></div>
        <div class="pop-up" v-if="showMsgPopUp">
            {{ cart_data.changes_msg }}
            <span>{{ cart_data.changes_span }}</span>
            <button @click="showMsgPopUp = false">{{ cart_data.ok }}</button>
        </div>
        <div class="hide-content" v-if="isOrderFaild"></div>
        <div class="pop-up" v-if="isOrderFaild">
            <p>{{ cart_data.payment_field }}</p>
            <button @click="isOrderFaild = false">{{ cart_data.ok }}</button>
        </div>
    </main>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import axios from 'axios';

export default {
    name: 'CartView',
    data() {
        return {
            cart: null,
            quantities: {},
            total: 0,
            products: null,
            cards: null,
            showMsgPopUp: false,
            isOrderFaild: false,
            cart_data: null,
            lang: "en",
            sliderValue: 0,
            tier_increment: 1,
            max_redeemable_points: 0,
            min_points_to_use: 0,
            monetary_value_per_point: 0,
            current_points: 0,
            usable_points: 0,
        }
    },
    components:{
        VueSlider
    },
    watch:{
        sliderValue(){
            if (this.sliderValue < this.min_points_to_use) {
                this.$nextTick(()=>{
                    if (this.sliderValue < this.min_points_to_use/2) {
                        this.sliderValue = 0;
                    } else {
                        this.sliderValue = this.min_points_to_use
                    }
                    this.$refs.slider.setValue(this.sliderValue)
                })
            }
        }
    },
    methods: {
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        async getCart(lang) {
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/cart`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": lang
                        },
                    }
                );
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    this.showMsgPopUp = response.data.data.is_cart_updated
                    this.total = response.data.data.total
                    this.tier_increment= response.data.data.tier_increment;
                    this.max_redeemable_points= response.data.data.max_redeemable_points;
                    // this.max_redeemable_points= 2000;
                    this.min_points_to_use= response.data.data.min_points_to_use;
                    this.current_points= Math.floor(response.data.data.current_points);
                    this.usable_points= response.data.data.usable_points;

                    this.products = response.data.data.products

                    for (let i = 0; i < this.products.length; i++) {
                        this.products[i].product_type = 1;
                        this.quantities[`product_${this.products[i].id}`] = this.products[i].qty
                    }
                    this.cards = response.data.data.cards
                    for (let i = 0; i < this.cards.length; i++) {
                        this.cards[i].product_type = 2;
                        this.quantities[`card_${this.cards[i].id}`] = this.cards[i].qty
                    }
                    this.cart = this.products.concat(this.cards)
                    this.monetary_value_per_point = parseFloat(response.data.data.monetary_value_per_point);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async addCardToCart(product_id, qty) {
            try {
                const response = await axios.post(`${API_URL}/cards/${product_id}/add-cart`, {
                    qty: qty,
                    type: 'update',
                },
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token')
                        }
                    },
                );
                if (response.data.status === true) {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    setTimeout(() => {
                        $('.loader').fadeOut()
                        this.getCart()
                    }, 0);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async addProductToCart(product_id, qty) {
            try {
                const response = await axios.post(`${API_URL}/products/${product_id}/add-cart`, {
                    qty: qty,
                    type: 'update',
                },
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token')
                        }
                    },
                );
                if (response.data.status === true) {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    setTimeout(() => {
                        $('.loader').fadeOut()
                        this.getCart()
                    }, 0);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async deleteCardToCart(product_id) {
            $('.loader').fadeIn()
            try {
                const response = await axios.delete(`${API_URL}/cards/${product_id}/delete-cart`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token')
                        }
                    },
                );
                if (response.data.status === true) {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    setTimeout(() => {
                        $('.loader').fadeOut()
                        if (this.cart.length == 1)
                            window.location.reload()
                        else
                        this.getCart()
                    }, 2000);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async deleteProductToCart(product_id) {
            $('.loader').fadeIn()
            try {
                const response = await axios.delete(`${API_URL}/products/${product_id}/delete-cart`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token')
                        }
                    },
                );
                if (response.data.status === true) {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    setTimeout(() => {
                        $('.loader').fadeOut()
                        if (this.cart.length == 1)
                            window.location.reload()
                        else
                            this.getCart()
                    }, 2000);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        getHomeData() {
            this.setLangCookies()
            let data = require('../assets/api/cart.json');
            this.cart_data = this.lang == 'ar' ? data.ar : data.en
            this.getCart(this.lang)
        },
        goToCheckout(){
            localStorage.removeItem('points')
            if (this.sliderValue >= this.min_points_to_use) {
                localStorage.setItem('points', JSON.stringify(this.sliderValue))
            }
            this.$router.push('/checkout')
        }
    },
    created() {
        this.getHomeData()
        if (this.$route.query.success && this.$route.query.success === 'false')
            this.isOrderFaild = true;
    },
    mounted() {
        $(document).mousemove(function (e) {
            $('.hint-pop-up').css({
                top: e.clientY,
                left: e.pageX + 10 // Adjust the position to 10px to the right of the mouse
            });
        });
    },
}
</script>
<style>;
.slider-container{
    padding-top: 2.5rem;
}
.points-header {
    display: flex;
    justify-content: center;
    color: #e63b12;
    font-weight: 900;
}
.point-balance-container {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 1.125rem;
    color: #0b5177;;
    font-weight: 600;
}
.point-slider {
    margin-top: 3rem;
}
.point-info-container{
    margin-top: 2rem;
    font-size: 14px;
    display: flex;
    justify-content: center;
}
.redeem-text{
    color: #0b5177;;
    font-weight: 600;
}
.egp-text{
    color: #e63b12;
    font-weight: 600;
}
</style>
<style scoped>@import './../assets/css/home.css';
.hint-pop-up {
    position: fixed;
    display: none;
    padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    z-index: 99999;
    font-size: 12px;
    border-radius: 10px;
}

.prod-name:hover .hint-pop-up {
    display: block;
}

.prod-name {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
}

.total, .qty, .price, .cart-buttons{
    text-align: center;
}

.cart-buttons {
    justify-content: center;
}
</style>