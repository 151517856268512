<template>
    <main class="card_wrapper checkout_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i
                    :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang ==
                        'en' ? 'Checkout' : 'اتمام الشراء' }}
            </div>
        </div>

        <div class="container">

            <h1 v-if="!cart || cart.length == 0"
                style="width:100%;margin: 5rem 0px; text-align: center; color: rgb(113, 113, 113);">{{ lang == 'en' ?
                    'Your Cart is Empty' : 'المفضلة فارغة' }}</h1>

            <div class="table_wrapper" v-if="cart && cart.length > 0">

                <table>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ lang == 'en' ? 'Your Order' : 'طلبك' }}</span>

                            </th>
                            <th>
                                <span>{{ lang == 'en' ? 'Price' : 'السعر' }}</span>
                            </th>
                            <th>
                                <span>{{ lang == 'en' ? 'Quantity' : 'كمية' }}</span>
                            </th>
                            <th>
                                <span>{{ lang == 'en' ? 'Total' : 'المجموع' }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in cart" :key="product.id">
                            <td>
                                <div class="prod-name"
                                    @click="product.product_type == 1 ? this.$router.push(`/product/${product.id}/${replaceSpaces(product.name)}`) : this.$router.push(`/card/${product.id}/${replaceSpaces(product.name)}`)">
                                    <!-- <p class="prod-name"> -->
                                        <img :src="product.img" :alt="product.name">
                                        <p>
                                            {{ product.name.length >= 39 ? product.name.slice(0, 40) + '...' :
                                                product.name }}
                                        </p>

                                        <span class="hint-pop-up" v-if="product && product.name.length > 39">{{
                                            product.name }}</span>
                                    <!-- </p> -->
                                </div>
                            </td>
                            <td>
                                <div class="price">
                                    <p>{{ product.price_after_discount ? product.price_after_discount.toLocaleString() :
                                        product.price.toLocaleString() }} {{ lang == 'en' ? 'EGP' : 'جنيه' }}</p>
                                </div>
                            </td>
                            <td>
                                <div class="Quantity">
                                    <p class="stock">{{ product.qty }}</p>
                                </div>
                            </td>
                            <td>
                                <div class="Total">
                                    <p class="stock">{{ product.total_price }}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="head">
                    <h1>{{ page_data.head }}</h1>
                    <hr>
                </div>
                <form action="">
                    <div class="form-group">
                        <label for="name">{{ lang == 'en' ? 'Full Name' : 'الاسم كامل' }}</label>
                        <input type="text" name="name" id="name" v-model="name">
                    </div>
                    <div class="form-group">
                        <label for="country">{{ lang == 'en' ? 'Country' : 'البلد' }}</label>
                        <input type="text" name="country" id="country" v-model="country" disabled>
                    </div>
                    <div class="form-group">
                        <label for="city">{{ lang == 'en' ? 'City' : 'المدينة' }}</label>
                        <input type="text" name="city" id="city" v-model="city">
                    </div>
                    <div class="form-group">
                        <label for="street">{{ lang == 'en' ? 'Street' : 'الشارع' }}</label>
                        <input type="text" name="street" id="street" v-model="street">
                    </div>
                    <div class="form-group">
                        <label for="phone">{{ lang == 'en' ? 'Phone Number' : 'رقم الهاتف' }}</label>
                        <input type="text" name="phone" id="phone" v-model="phone">
                    </div>

                    <div class="form-group"></div>

                    <div class="form-group">
                        <v-checkbox v-model="save_address">
                            <template v-slot:label>
                                <div>
                                    <h6>Save My Billing Address for next orders</h6>
                                </div>
                            </template>
                        </v-checkbox>
                    </div>
                </form>

                <div class="shipping-container">
                    <h5>
                        {{ lang == 'en' ? 'Choose shipping method' : 'اختر طريقة الشحن' }}
                    </h5>
                    <form action="">
                        <div class="form-group" v-if="!products.length && payment_method != 10 && payment_method != 12">
                            <label for="shipping_method_1" :class="shipping_method === '1' ? 'selected' : ''">
                                <input type="radio" name="shipping_method" id="shipping_method_1"
                                    v-model="shipping_method" value="1" @change="getCartPrice">
                                {{ lang == 'en' ? 'Online' : 'توصيل اونلاين' }}
                            </label>
                        </div>
                        <div class="form-group">
                            <label for="shipping_method_2" :class="shipping_method === '2' ? 'selected' : ''">
                                <input type="radio" name="shipping_method" id="shipping_method_2"
                                    v-model="shipping_method" value="2" @change="getCartPrice">
                                {{ lang == 'en' ? '2day' : 'مندوب -خلال يومان عمل' }}
                            </label>
                        </div>
                        <div class="form-group" >
                            <label for="shipping_method_3" :class="shipping_method === '3' ? 'selected' : ''">
                                <input type="radio" name="shipping_method" id="shipping_method_3"
                                    v-model="shipping_method" value="3" @change="getCartPrice">
                                {{ lang == 'en' ? 'Store pickup' : 'الاستلام من الفرع' }}
                            </label>
                        </div>
                    </form>
                </div>
            </div>
            <div class="total" v-if="cart && cart.length > 0">
                <!-- <div class="head">
                    {{ lang == 'en' ? 'Your Order' : 'طلبك' }}
                </div> -->
                <div class="bottom">
                    <div class="products">
                        <!-- <h5>{{ lang == 'en' ? 'Products' : 'المنتجات' }} <span>{{ lang == 'en' ? 'Total' : 'المجموع'
                                }}</span></h5>
                        <p v-for="product in cart" :key="product.id">
                            {{ product.name.length >= 40 ? product.name.slice(0, 40) + '...' : product.name }}
                            <span>
                                {{ product.product_type == 1 ? quantities[`product_${product.id}`] :
                                    quantities[`card_${product.id}`] }} x
                                {{ product.price_after_discount ? product.price_after_discount.toLocaleString() :
                                    product.price.toLocaleString() }}
                            </span>
                        </p> -->
                        <h5>{{ lang == 'en' ? 'Choose payment method' : 'اختر طريقة الدفع' }}</h5>
                        <form action="">

                            <label v-if="wallet_balance > 0" :for="`payment_method_wallet`" class="selected">
                                <input checked type="radio" name="wallet_payment" :id="`payment_method_wallet`"
                                    value="wallet_payment">
                                Wallet
                            </label>

                            <div v-for="paymentMethod in getPaymentMethodsFilteredData" :key="paymentMethod.id"
                                class="form-group">
                                <label :for="`payment_method_${paymentMethod.id}`"
                                    :class="payment_method == paymentMethod.id ? 'selected' : ''">
                                    <input :disabled="wallet_balance > total" type="radio" name="payment_method"
                                        :id="`payment_method_${paymentMethod.id}`" v-model="payment_method"
                                        :value="paymentMethod.id" @change="getCartPrice">
                                    {{ paymentMethod.name }}
                                </label>
                                <span v-if="paymentMethod.description && paymentMethod.id == payment_method"
                                    style="padding: 10px;display: block;margin-top: 10px;border-radius: .4rem;font-size: 13px;background: #d5dfe440;text-align: center;white-space: break-spaces;max-width: 320px;">
                                    {{ paymentMethod.description }}
                                </span>
                                <!-- <label v-if="paymentMethod.wallet_phone_required && paymentMethod.id == payment_method" :for="`payment_method_wallet_number_${paymentMethod.id}`" > -->
                                <input v-if="paymentMethod.wallet_phone_required && paymentMethod.id == payment_method"
                                    placeholder="Enter Wallet Number" type="text" name="payment_method"
                                    :id="`payment_method_wallet_number_${paymentMethod.id}`" v-model="wallet_number">
                            </div>
                        </form>

                        <div v-if="!used_points" class="form-group"
                            style="flex-direction: column; margin-top: 10px; gap: 0px; align-items: flex-start;">
                            <label for="coupon">{{ lang == 'en' ? 'Coupon' : 'قسيمة الخصم' }}</label>
                            <div class="coupon-container">
                                <input type="text" name="coupon" id="coupon" v-model="coupon"
                                    :placeholder="lang == 'en' ? 'Coupon' : 'الكود'">
                                <button v-if="!coupon_discount" class="coupon-button"
                                    @click="checkCoupon(coupon, shipping_method)">{{ lang == "en" ? "Apply" : "حسنا"
                                    }}</button>
                                <button v-else class="coupon-button"
                                    @click="checkCoupon(coupon, shipping_method, true)">{{ lang ==
                                        "en" ? "Remove" :
                                    "حسنا" }}</button>

                            </div>
                        </div>
                        <h5>{{ lang == 'en' ? 'Subtotal' : 'المجموع الفرعي' }} <span>{{ total.toLocaleString() }} {{
                            lang == 'en' ? 'EGP' : 'جنيه مصري' }}</span></h5>
                        <h5 v-if="shipping_money">{{ lang == 'en' ? 'Shipping' : 'الشحن' }} <span>{{
                            shipping_money.toLocaleString() }} {{ lang == 'en' ? 'EGP' : 'جنيه مصري' }}</span></h5>
                        <h5 v-if="payment_fees">{{ lang == 'en' ? 'Payment Fees' : 'تكاليف الدفع' }} <span>{{
                            payment_fees.toLocaleString() }} {{ lang == 'en' ? 'EGP' : 'جنيه مصري' }}</span></h5>
                        <h5 v-if="coupon_discount && coupon">{{ lang == 'en' ? 'Coupon' : 'خصم' }} <span>- {{
                            coupon_discount.toLocaleString() }} {{ lang == 'en' ? 'EGP' : 'جنيه مصري' }}</span></h5>
                        <h5 v-if="discount">{{ lang == 'en' ? 'Points' : 'خصم' }} <span>- {{ discount.toLocaleString()
                                }} {{ lang == 'en' ? 'EGP' : 'جنيه مصري' }}</span></h5>
                        <h5 v-if="wallet_balance > 0">{{ lang == 'en' ? 'Wallet' : 'محفظة' }} <span>- {{
                            wallet_balance.toLocaleString() }} {{ lang == 'en' ? 'EGP' : 'جنيه مصري' }}</span></h5>
                        <h4>{{ lang == 'en' ? 'Total' : "المجموع" }} <span>{{ (total + payment_fees + shipping_money -
                            wallet_balance - discount - coupon_discount).toLocaleString() }} {{ lang == 'en' ? 'EGP'
                                    : 'جنيه مصري' }}</span></h4>
                    </div>
                    <button
                        @click="addOrder(name, country, city, street, phone, ipAddress, coupon, shipping_method, payment_method)">{{lang == 'en' ? 'Place Order' : 'اكمل الطلب' }}</button>
                    <p class="my-3 policy-text">{{ lang == 'en' ? 'By Clicking Place Order, You agree to' : "بالنقر على تقديم الطلب، فإنك توافق على" }} 
                        <router-link to="/terms_condition" target="_blank">{{ lang == 'en' ? 'Terms of Service' : 'بنود الخدمة' }}</router-link>
                        {{ lang == 'en' ? 'and' : "و" }} 

                        <router-link to="/privacy_policy" target="_blank">{{ lang == 'en' ? 'Privacy Policy' : 'سياسة الخصوصية' }}</router-link>
                    </p>
                </div>
            </div>
            <!-- <h1 v-if="!cart || cart.length == 0"
                style="width:100%;margin: 5rem 0px; text-align: center; color: rgb(113, 113, 113);">{{ lang == "en" ?
                    "Your Cart is Empty" : "عربة التسوق فارغة" }}</h1> -->
        </div>
        <div class="hide-content" v-if="payment_msg_popup"></div>
        <div class="pop-up" v-if="payment_msg_popup">
            <p v-html="payment_msg"></p>
            <button @click="this.$router.push('/my-orders')">{{ lang == "en" ? "Ok" : "حسنا" }}</button>
        </div>
    </main>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';

export default {
    name: 'CheckoutView',
    data() {
        return {
            save_address: true,
            cart: null,
            quantities: {},
            total: 0,
            subtotal: 0,
            products: null,
            cards: null,
            ipAddress: null,
            name: '',
            city: '',
            street: '',
            phone: '',
            home: '',
            coupon: '',
            coupon_discount: 0,
            shipping_method: '',
            payment_method: '',
            shipping_money: 0,
            payment_msg: null,
            payment_msg_popup: false,
            order_id: null,
            installment_option: false,
            lang: 'en',
            country: 'Egypt',
            page_data: null,
            payment_fees: 0,
            discount: 0,
            used_points: 0,
            paymentMethods: [],
            wallet_number: null,
            wallet_balance: 0,
            user_address: null,
        }
    },
    computed: {
        getPaymentMethodsFilteredData() {
            if (this.shipping_method == "1") {
                return this.paymentMethods.filter(paymentMethod => paymentMethod.id != 10 && paymentMethod.id != 12)
            } 
            return this.paymentMethods;
        }
    },
    methods: {
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        async getCart(lang) {
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/cart`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": lang
                        },
                    }
                );
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    this.total = response.data.data.total
                    this.products = response.data.data.products

                    for (let i = 0; i < this.products.length; i++) {
                        this.products[i].product_type = 1;
                        this.quantities[`product_${this.products[i].id}`] = this.products[i].qty
                    }
                    this.cards = response.data.data.cards
                    for (let i = 0; i < this.cards.length; i++) {
                        this.cards[i].product_type = 2;
                        this.quantities[`card_${this.cards[i].id}`] = this.cards[i].qty
                    }
                    this.cart = this.products.concat(this.cards)
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async getCartPrice() {
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/cart/price?payment_id=${this.payment_method}&shipping_method=${this.shipping_method}${this.used_points ? '&redeem_points=' + this.used_points : ''}`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token')
                        },
                    }
                );
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    this.shipping_money = response.data.data.shipping_fees
                    this.installment_option = response.data.data.installment_option
                    this.discount = response.data.data.discount;
                    this.payment_fees = response.data.data.payment_fee
                    // switch (this.payment_method) {
                    //     case 0:
                    //         this.payment_fees = parseInt(response.data.data.credit_fees);
                    //         break;

                    //     case 1:
                    //         this.payment_fees = parseInt(response.data.data.wallet_fees);
                    //         break;

                    //     case 2:
                    //         this.payment_fees = parseInt(response.data.data.fawry_fees);
                    //         break;

                    //     case 3:
                    //         this.payment_fees = parseInt(response.data.data.aman_masary_fees);
                    //         break;

                    //     case 4:
                    //         this.payment_fees = parseInt(response.data.data.valu_fees);
                    //         break;

                    //     case 5:
                    //         this.payment_fees = parseInt(response.data.data.bank_installments_fees);
                    //         break;

                    //     case 6:
                    //         this.payment_fees = parseInt(response.data.data.contact_fees);
                    //         break;

                    //     case 7:
                    //         this.payment_fees = parseInt(response.data.data.forsa_fees);
                    //         break;

                    //     case 8:
                    //         this.payment_fees = parseInt(response.data.data.other_payment_fees);
                    //         break;
                    //     case 9:
                    //         this.payment_fees = parseInt(response.data.data.premium_fees);
                    //         break;

                    //     default:
                    //         this.payment_fees = 0;
                    //         break;
                    // }

                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async addOrder(name, country, city, street, phone, ip_address, coupon, shipping_method, payment_method) {
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.post(`${API_URL}/users/orders`,
                    {
                        full_name: name,
                        country: country,
                        city: city,
                        street_address: street,
                        phone: phone,
                        ip_address: ip_address,
                        coupon: coupon,
                        shipping_method: shipping_method,
                        payment_id: payment_method,
                        redeem_points: this.used_points,
                        wallet_number: this.wallet_number,
                        save_address: this.save_address,
                    },
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    setTimeout(() => {
                        if (response.data.data.payment_link) {
                            window.location.href = response.data.data.payment_link
                            $('.loader').fadeOut()
                        }
                        if (response.data.data.message) {
                            $('.loader').fadeOut()
                            this.payment_msg = response.data.data.message
                            this.payment_msg_popup = true
                        }
                        if (!response.data.data.message && !response.data.data.payment_link) {
                            $('#errors').fadeIn('slow')
                            setTimeout(() => {
                                $('#errors').fadeOut('slow')
                                window.location.href = `/order/${response.data.data.order_id}`
                            }, 3000);
                        }
                    }, 3000);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async getPaymentMethods() {

            try {
                const response = await axios.get(`${API_URL}/payments?platform=web`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                if (response.data.status === true) {
                    this.paymentMethods = response.data.data
                } else {
                    $('.loader').fadeOut()
                    this.paymentMethods = []
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        async checkCoupon(code, shipping_method, isRemove = false) {
            if (isRemove) {
                this.coupon = ""
                code = ""
            }

            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.post(`${API_URL}/users/cart/coupons/check`,
                    {
                        code: code,
                        shipping_method: shipping_method,
                        payment_id: this.payment_method
                    },
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                if (response.data.account_status === true && response.data?.data?.discount) {
                    this.coupon_discount = response.data.data.discount;
                } else {
                    this.coupon_discount = 0
                }

                if (!isRemove && response.data?.errors?.length) {
                    document.getElementById('errors').innerHTML = ''
                    let err = document.createElement('div')
                    err.classList = 'error'
                    err.innerHTML = response.data.errors[0]
                    document.getElementById('errors').append(err)
                    $('#errors').fadeIn('slow')
                    $('.loader').fadeOut()

                    setTimeout(() => {
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }
                $('.loader').fadeOut()

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        getIPAddress() {
            axios.get('https://api.ipify.org?format=json')
                .then(response => {
                    this.ipAddress = response.data.ip;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async getWalletBalance() {
            this.is_data_reading = true;
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/wallets`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                this.is_data_reading = false
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    let data = response.data.data
                    this.wallet_balance = data?.balance
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                this.is_data_reading = false
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },

        async getUserAddress() {
            this.is_data_reading = true;
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.get(`${API_URL}/users/address`,
                    {
                        headers: {
                            "AUTHORIZATION": 'Bearer ' + sessionStorage.getItem('user_token'),
                            "lang": this.lang
                        },
                    }
                );
                this.is_data_reading = false
                if (response.data.status === true) {
                    $('.loader').fadeOut()
                    let data = response?.data?.data
                    if(data){
                        // this.user_address = data
                    this.name = data?.full_name;
                    this.phone = data?.phone;
                    this.city = data?.city
                    this.street = data?.street_address
                    }
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                this.is_data_reading = false
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },

        getHomeData() {
            this.setLangCookies()
            let data = require('../assets/api/checkout.json');
            this.page_data = this.lang == 'ar' ? data.ar : data.en
            this.getWalletBalance();
            this.getCart(this.lang)
            this.getIPAddress();
            this.getCartPrice(this.lang)
            this.getPaymentMethods()
            this.getUserAddress();
            this.country = this.lang == 'ar' ? "مصر" : "Egypt"
        },
    },
    created() {
        this.used_points = JSON.parse(localStorage.getItem('points'))
        this.getHomeData()

        const user = JSON.parse(sessionStorage.getItem("user"));
        console.log("user =>>>>>", user);
        if (user?.phone) {
            this.phone = user.phone;
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
@import './../assets/css/home.css';

.coupon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.coupon-button {
    width: 30% !important;
    margin-top: 1rem !important;
    margin-left: 1rem !important;
}

.shipping-container {
    margin-top: 2rem;
}

.shipping-container .form-group label {
    border: 1px solid #d5dfe4;
    width: 100%;
    border-radius: .4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: .9rem 1rem;
    color: #33332c;
    cursor: pointer;
}

.shipping-container form input {
    width: 5% !important;
}

.policy-text {
    color: #33332c;
}

.prod-name {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
}

.prod-name p {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
    /* font-weight: 600; */
    font-size:clamp(0.8rem, calc(0.6011rem + 0.8602vw), 1.3rem)
}
.prod-name img {
    width: clamp(4.375rem, calc(3.3804rem + 4.3011vw), 6.875rem);
    height: clamp(3.75rem, calc(3.5013rem + 1.0753vw), 4.375rem);
    -o-object-fit: contain;
    object-fit: contain;
}

.price p {
    text-align: center;
}

.stock {
    text-align: center;
}
</style>