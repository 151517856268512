<template>
    <main class="register_wrapper">
        <div class="page-head">
            <div class="container">
                <router-link to="/">{{ lang == 'en' ? 'Home' : 'الرئيسية' }}</router-link> <i :class="lang == 'en' ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i> {{ lang == 'en' ? 'Account' : 'الحساب' }}
            </div>
        </div>
        <div class="container">
            <div class="form">
                <div class="head">
                    <h1>
                        {{ lang == 'en' ? 'Account Login' : 'تسجل الدخول' }}
                    </h1>
                    <!-- <p>{{ lang == 'en' ? 'Do not have an account?' : 'ليس لديك حساب؟' }} <router-link to="/register"> {{ lang == 'en' ? 'SignUp' : 'انشاء حساب' }}</router-link></p> -->
                </div>
                <button @click="callback" class="login-with-google-btn">
                    <img width="44" height="44" src="./../assets/imgs/google_icon.jpg" alt="Sign in with Google" class="google-signin-icon">
                    <span class="google-signin-text">{{ lang == 'en' ? 'Continue with Google' : 'الدخول بحساب جوجل' }}</span>
                </button>
                <div v-if="!isUserExist && !isSignUpRequired" class="input">
                    <input @keyup.enter="handleEnterOnEmail()" type="text" name="phone" autofocus id="phone" :placeholder="lang == 'en' ? 'Email or Phone Number' : 'البريد الالكتروني او رقم الهاتف'" v-model="phone">
                    <img src="./../assets/imgs/user-solid.svg" alt="phone icon">
                </div>
                <div v-if="isUserExist" class="input">
                    <input type="password" name="password" id="password" :placeholder="lang == 'en' ? 'Password' : 'كلمة المرور'" v-model="password">
                    <img src="./../assets/imgs/lock-solid.svg" alt="lock icon">
                </div>

                <!-- signup fields -->
                <div v-if="isSignUpRequired" class="input">
                    <input type="email" name="email" id="email" :placeholder="lang == 'en' ? 'Email' : 'البريد الالكتروني'" v-model="email">
                    <img src="./../assets/imgs/envelope-regular.svg" alt="email icon">
                </div>
                <div v-if="isSignUpRequired" class="input">
                    <input type="text" name="phone" id="phone" :placeholder="lang == 'en' ? 'Phone Number' : 'رقم الهاتف'" v-model="registerPhone">
                    <img src="./../assets/imgs/phone-solid.svg" alt="phone icon">
                </div>
                <div v-if="isSignUpRequired" class="input">
                    <input type="text" name="dob" id="dob" :placeholder="lang == 'en' ? 'Date Of Birth' : 'تاريخ الميلاد'"
                    onfocus="(this.type='date')" onblur="(this.type='text')" class="form-control" v-model="dob" @blur="validateDate()">
                    <img src="./../assets/imgs/calendar-days-regular.svg" alt="calendar icon">
                </div>
                <div v-if="isSignUpRequired" class="input">
                    <input type="password" name="password" id="password" :placeholder="lang == 'en' ? 'Password' : 'كلمة المرور'" v-model="password">
                    <img src="./../assets/imgs/lock-solid.svg" alt="lock icon">
                </div>
                <div v-if="isSignUpRequired" class="input">
                    <input type="password" name="password_confirmation" id="password_confirmation" :placeholder="lang == 'en' ? 'Confirm Password' : 'تاكيد كلمة المرور'" v-model="password_confirmation">
                    <img src="./../assets/imgs/lock-solid.svg" alt="lock icon">
                </div>
                <div v-if="isUserExist || isSignUpRequired">
                    <Turnstile :sitekey="siteKey" @verified="handleVerification" />
                    <!-- <vue-recaptcha
                        sitekey="1x00000000000000000000AA"
                        size="normal"
                        theme="light"
                        :loading-timeout="loadingTimeout"
                        @verify="recaptchaVerified"
                        @expire="recaptchaExpired"
                        @fail="recaptchaFailed"
                        @error="recaptchaError"
                        ref="vueRecaptcha"
                        >
                    </vue-recaptcha> -->
                </div>
                <button  v-if="!isUserExist && !isSignUpRequired" type="submit" class="button" @click="continueWithCredentials()">{{ lang == 'en' ? 'Continue' : 'تسجيل الدخول' }}</button>
                <button v-if="isUserExist" type="submit" class="button" @click="login(this.phone, this.password)">{{ lang == 'en' ? 'Login' : 'تسجيل الدخول' }}</button>
                <p v-if="isSignUpRequired">{{ lang == 'en' ? 'By clicking "Register",' : 'بالضغط هنا والمتابعة،' }} <br> {{ lang == 'en' ? 'I agree to the' : 'أنا أوافق على' }} <router-link to="/terms_condition" target="_blank">{{ lang == 'en' ? 'Terms of Service' : 'بنود الخدمة' }}</router-link> {{ lang == 'en' ? 'and' : ' و' }} <router-link to="/privacy_policy" target="_blank">{{ lang == 'en' ? 'Privacy Policy' : 'سياسة الخصوصية' }}</router-link></p>
                <button v-if="isSignUpRequired" type="submit" class="button" @click="registerMethod(this.email, this.registerPhone, this.dob, this.password, this.password_confirmation)">{{ lang == 'en' ? 'Register Account' : 'انشاء حساب' }}</button>
                <p v-else>{{ lang == 'en' ? 'Forgot Your Password?' : 'نسيت كلمة السر؟' }} <router-link to="/forgot-password">{{ lang == 'en' ? 'Click Here' : 'انقر هنا' }}</router-link></p>
                <!-- <div class="or">
                    <span></span>
                    or
                    <span></span>
                </div>
                <router-link to="" class="sign"><img src="./../assets/imgs/facebook_icon.jpg" alt="facebook_icon">Sign up With
                    Facebook</router-link>
                <router-link to="" class="sign"><img src="./../assets/imgs/google_icon.jpg" alt="google_icon">Sign up With
                    Google</router-link> -->
            </div>
        </div>
    </main>
</template>

<script>
import Turnstile from "@/components/Turnstile.vue";
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;


import axios from 'axios';
import { setCookie } from './../assets/js/set-cookie'
import { googleTokenLogin } from "vue3-google-login"
// import vueRecaptcha from "vue3-recaptcha2";

export default {
    name: 'LoginView',
    data() {
        return {
            phone: null,
            password: null,
            email: null,
            registerPhone: null,
            dob: null,
            password_confirmation: null,
            errorMsg: "",
            isUserExist: false,
            isSignUpRequired: false,
            lang: 'en',
            // g_recaptcha_response: null,
            siteKey: process.env.VUE_APP_SITE_KEY,
            token: null,
        }
    },
    // components:{
    //     vueRecaptcha
    // },
    components: { Turnstile },
    methods: {
        handleVerification(token) {
      this.token = token;
    },
        async login(phone, password) {
            $('.loader').fadeIn().css('display', 'flex')
            if (!this.token) {
                alert("Please complete the CAPTCHA");
                return;
            }
            try {
                const response = await axios.post(`${window.main_url}/login`, {
                    email: phone,
                    password: password,
                    // g_recaptcha_response: this.g_recaptcha_response
                },
                {
                    headers: {
                        "lang": this.lang
                    }
                }
                );
                if (response.data.status === true) {
                    sessionStorage.setItem('user_token', response.data.data.token)
                    setCookie('user_token', response.data.data.token, 30)
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    $('#errors').fadeIn('slow')
                    setTimeout(() => {
                        $('.loader').fadeOut()
                        $('#errors').fadeOut('slow')
                        if (response.data.data.activation_str == 'Active') {
                            window.location.href = '/'
                        } else if (response.data.data.activation_str == 'waiting_Active') {
                            window.location.href = '/verify'
                        } else if (response.data.data.activation_str == 'deactivated') {
                            document.getElementById('errors').innerHTML = ''
                            let error = document.createElement('div')
                            error.classList = 'error'
                            error.innerHTML = 'Your account has been banned'
                            document.getElementById('errors').append(error)
                            $('#errors').fadeIn('slow')

                            setTimeout(() => {
                                $('input').css('outline', 'none')
                                $('#errors').fadeOut('slow')
                            }, 3500);
                        }
                    }, 1300);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        handleEnterOnEmail(){
            if (this.phone) {
                this.continueWithCredentials()
            } else {
                let googleButton = document.querySelector('.login-with-google-btn');
                if (googleButton) {
                    googleButton.click()
                }
            }
        },
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        getHomeData() {
            this.setLangCookies()
        },

        callback() {
            googleTokenLogin().then((response) => {

                let payload= {
                    provider: 'google',
                    token: response.access_token,
                }
                this.loginApiCall(payload)
            }).catch((err)=>{
                console.log("error ocuured while google login", err)
            })
        },
        async loginApiCall(payload){
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.post(`${window.main_url}/social-login`, payload,
                {
                    headers: {
                        "lang": this.lang
                    }
                }
                );
                if (response.data.status === true) {
                    sessionStorage.setItem('user_token', response.data.data.token)
                    setCookie('user_token', response.data.data.token, 30)
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'success'
                    error.innerHTML = response.data.message
                    document.getElementById('errors').append(error)
                    $('#errors').fadeIn('slow')
                    setTimeout(() => {
                        $('.loader').fadeOut()
                        $('#errors').fadeOut('slow')
                        if (response.data.data.activation_str == 'Active') {
                            window.location.href = '/'
                        } else if (response.data.data.activation_str == 'waiting_Active') {
                            window.location.href = '/verify'
                        } else if (response.data.data.activation_str == 'deactivated') {
                            document.getElementById('errors').innerHTML = ''
                            let error = document.createElement('div')
                            error.classList = 'error'
                            error.innerHTML = 'Your account has been banned'
                            document.getElementById('errors').append(error)
                            $('#errors').fadeIn('slow')

                            setTimeout(() => {
                                $('input').css('outline', 'none')
                                $('#errors').fadeOut('slow')
                            }, 3500);
                        }
                    }, 1300);
                } else {
                    $('.loader').fadeOut()
                    document.getElementById('errors').innerHTML = ''
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement('div')
                        error.classList = 'error'
                        error.innerHTML = value
                        document.getElementById('errors').append(error)
                    });
                    $('#errors').fadeIn('slow')

                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                    }, 3500);
                }

            } catch (error) {
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },

        async continueWithCredentials(){
            $('.loader').fadeIn().css('display', 'flex')
            try {
                const response = await axios.post(`${window.main_url}/check-user-existence`, {email_or_phone: this.phone},
                {
                    headers: {
                        "lang": this.lang
                    }
                }
                );
                if (response.data.status === true) {
                    console.log("in        befor fadeout===>", response)
                    $('.loader').fadeOut()
                    if (response.data.account_status) {
                        this.isUserExist = true;
                    } else {
                        this.isSignUpRequired = true;
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (emailRegex.test(this.phone)) {
                            this.email = this.phone
                        } else {
                            this.registerPhone = this.phone
                        }
                    }
                } else {
                    $('.loader').fadeOut()
                    this.isSignUpRequired = true;
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (emailRegex.test(this.phone)) {
                        this.email = this.phone
                    } else {
                        this.registerPhone = this.phone
                    }
                }
            } catch(error){
                document.getElementById('errors').innerHTML = ''
                let err = document.createElement('div')
                err.classList = 'error'
                err.innerHTML = 'server error try again later'
                document.getElementById('errors').append(err)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()

                setTimeout(() => {
                    $('#errors').fadeOut('slow')
                }, 3500);

                console.error(error);
            }
        },
        validateDate() {
            const currentDate = new Date();
            const selectedDate = new Date(this.dob);
            // Check if the year is a 4-digit number
            const year = selectedDate.getFullYear();
            const age = Math.floor((currentDate - selectedDate) / (365.25 * 24 * 60 * 60 * 1000));
            if (this.dob) {
                this.errorMsg = ''
                if (!year || year < 1000 || year > 9999) {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'error'
                    error.innerHTML = "Please enter a valid 4-digit year."
                    this.errorMsg = "Please enter a valid 4-digit year."
                    document.getElementById('errors').append(error)
                    $('#errors').fadeIn('slow')
                    $('.loader').fadeOut()
                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                        $('.loader').fadeOut()
                    }, 3500);
                } else if (age <= 16 || age >= 100) {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'error'
                    error.innerHTML = "You must be more than 16 years old."
                    this.errorMsg = "You must be more than 16 years old."
                    document.getElementById('errors').append(error)
                    $('#errors').fadeIn('slow')
                    $('.loader').fadeOut()
                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                        $('.loader').fadeOut()
                    }, 3500);
                }
            }
        },
        async registerMethod(email, phone, dob, password, password_confirmation) {
            $('.loader').fadeIn().css('display', 'flex')
            if (!this.errorMsg) {
                if (password == password_confirmation)
                    try {
                        const response = await axios.post( `${window.main_url}/register`, {
                            email: email,
                            phone: phone,
                            dob: dob,
                            password: password,
                            password_confirmation: password_confirmation,
                            g_recaptcha_response: this.g_recaptcha_response
                        }, {
                            headers: {
                                "lang": this.lang
                            }
                        }
                        );
                        $('.loader').fadeOut()
                        if (response.data.status === true) {
                            sessionStorage.setItem('user_token', response.data.data.token)
                            setCookie('user_token', response.data.data.token, 30)
                            document.getElementById('errors').innerHTML = ''
                            let error = document.createElement('div')
                            error.classList = 'success'
                            error.innerHTML = response.data.message
                            document.getElementById('errors').append(error)
                            $('#errors').fadeIn('slow')
                            setTimeout(() => {
                                $('#errors').fadeOut('slow')
                                window.location.href = '/verify';
                            }, 4000);
                        } else {
                            document.getElementById('errors').innerHTML = ''
                            $.each(response.data.errors, function (key, value) {
                                let error = document.createElement('div')
                                error.classList = 'error'
                                error.innerHTML = value
                                document.getElementById('errors').append(error)
                            });
                            $('#errors').fadeIn('slow')

                            setTimeout(() => {
                                $('input').css('outline', 'none')
                                $('#errors').fadeOut('slow')
                            }, 3500);
                        }

                    } catch (error) {
                        document.getElementById('errors').innerHTML = ''
                        let err = document.createElement('div')
                        err.classList = 'error'
                        err.innerHTML = 'server error try again later'
                        document.getElementById('errors').append(err)
                        $('#errors').fadeIn('slow')
                        $('.loader').fadeOut()

                        setTimeout(() => {
                            $('#errors').fadeOut('slow')
                        }, 3500);

                        console.error(error);
                    }
                else {
                    document.getElementById('errors').innerHTML = ''
                    let error = document.createElement('div')
                    error.classList = 'error'
                    error.innerHTML = 'password and its confirmation do not match'
                    document.getElementById('errors').append(error)
                    $('#errors').fadeIn('slow')
                    $('.loader').fadeOut()
                    setTimeout(() => {
                        $('input').css('outline', 'none')
                        $('#errors').fadeOut('slow')
                        $('.loader').fadeOut()
                    }, 3500);
                }
            } else {
                document.getElementById('errors').innerHTML = ''
                let error = document.createElement('div')
                error.classList = 'error'
                error.innerHTML = this.errorMsg
                document.getElementById('errors').append(error)
                $('#errors').fadeIn('slow')
                $('.loader').fadeOut()
                setTimeout(() => {
                    $('input').css('outline', 'none')
                    $('#errors').fadeOut('slow')
                    $('.loader').fadeOut()
                }, 3500);
            }

        },
        recaptchaVerified(response){
            this.g_recaptcha_response = response;
        }
    },
    created() {
        this.getHomeData()
    },
}
</script>

<style scoped>@import './../assets/css/account.css';</style>
<style>
.login-with-google-btn {
  background-color: #4285f4;
  color: #fff;
  padding: 2px 2px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 16px;
}

.login-with-google-btn .google-signin-icon {
  width: 44px;
  height: 44px;
  border-radius: 4px;
}

.login-with-google-btn .google-signin-text {
  padding-left: 0; /* Adjust padding to accommodate the icon */
}
.google-signin-text{
    width: 100%;
}
.g-btn-wrapper {
    width: 100% !important;
}
</style>