import { createStore } from 'vuex';
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL || 'https://support.egyptgamestore.com/api'

export const store = createStore({
  state: {
    categories: [],
    subCategoriesMap: {},
    cat_loading: false,
    sub_cat_loading: {},
    digital_store_categories: [],
    sub_digital_store_CategoriesMap: {},
    cat_digital_store_loading: false,
    sub_digital_store_cat_loading: {},
    countryMap: {},
    country_loading: {},
    lang: 'en',
  },

  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setSubCategoriesMap(state, { categoryId, subCategories }) {
      state.subCategoriesMap[categoryId] = subCategories;
    },
    setSubCategoryLoading(state, { categoryId, value }) {
      state.sub_cat_loading[categoryId] = value;
    },
    setDigitalStoreCategories(state, categories) {
      state.digital_store_categories = categories;
    },
    setDigitalSubCategoriesMap(state, { categoryId, subCategories }) {
      state.sub_digital_store_CategoriesMap[categoryId] = subCategories;
    },
    setDigitalSubCategoryLoading(state, { categoryId, value }) {
      state.sub_digital_store_cat_loading[categoryId] = value;
    },
    setCountryMap(state, { categoryId, countries }) {
      state.countryMap[categoryId] = countries;
    },
    setCountryLoading(state, { categoryId, value }) {
      state.country_loading[categoryId] = value;
    },
    setLoading(state, { key, value }) {
      state[key] = value;
    },
    setError(state, error) {
      state.error = error;
    },
    setLang(state, lang) {
        state.lang = lang;
      },
  },

  actions: {
    updateLang({ commit }, lang) {
        commit('setLang', lang);
    },
    async fetchAllCategoriesAndSubCategories({ commit, state }) {
      commit('setLoading', { key: 'cat_loading', value: true });
      commit('setError', null);

      try {
        const response = await axios.get(
          `${API_URL}/categories/children?category_id=2`,
          { headers: { lang: state.lang } }
        );

        if (response.data.status === true) {
          commit('setCategories', response.data.data);
          // for (let category of state.categories) {
          //   if (category.has_children) {
          //     await this.dispatch('fetchSubCategories', category.id);
          //   }
          // }
        } else {
          commit('setError', 'Error fetching categories.');
        }
      } catch (error) {
        commit('setError', 'Server error, try again later.');
        console.error(error);
      } finally {
        commit('setLoading', { key: 'cat_loading', value: false });
      }
    },

    async fetchSubCategories({ commit, state }, categoryId) {
      if (state.subCategoriesMap[categoryId]) {
        return; // If subcategories already exist, don't fetch again
      }

      commit('setSubCategoryLoading', { categoryId, value: true });

      try {
        const response = await axios.get(
          `${API_URL}/categories/children/children?category_id=${categoryId}`,
          { headers: { lang: state.lang } }
        );

        if (response.data.status === true) {
          commit('setSubCategoriesMap', { categoryId, subCategories: response.data.data });
        }
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      } finally {
        commit('setSubCategoryLoading', { categoryId, value: false });
      }
    },

    async fetchAlldigitalCategoriesAndSubCategories({ commit, state }) {
      commit('setLoading', { key: 'cat_digital_store_loading', value: true });
      commit('setError', null);

      try {
        const response = await axios.get(
          `${API_URL}/categories/children?category_id=3`,
          { headers: { lang: state.lang } }
        );

        if (response.data.status === true) {
          commit('setDigitalStoreCategories', response.data.data);
        } else {
          commit('setError', 'Error fetching digital store categories.');
        }
      } catch (error) {
        commit('setError', 'Server error, try again later.');
        console.error(error);
      } finally {
        commit('setLoading', { key: 'cat_digital_store_loading', value: false });
      }
    },

    async fetchDigitalSubCategories({ commit, state }, categoryId) {
      // Check if subcategories already exist
      if (state.sub_digital_store_CategoriesMap[categoryId]) {
        return; // If subcategories already exist, don't fetch again
      }

      commit('setDigitalSubCategoryLoading', { categoryId, value: true });

      try {
        const response = await axios.get(
          `${API_URL}/categories/children/children?category_id=${categoryId}`,
          { headers: { lang: state.lang } }
        );

        if (response.data.status === true) {
          commit('setDigitalSubCategoriesMap', { categoryId, subCategories: response.data.data });
        }
      } catch (error) {
        commit('setError', 'Server error, try again later.');
        console.error('Error fetching digital subcategories:', error);
      } finally {
        commit('setDigitalSubCategoryLoading', { categoryId, value: false });
      }
    },

    async fetchCountries({ commit, state }, categoryId) {
      // Check if countries already exist for the given categoryId
      if (state.countryMap[categoryId]) {
        return; // If countries already exist, don't fetch again
      }
      commit('setCountryLoading', { categoryId, value: true });
      try {
        const response = await axios.get(
          `${API_URL}/countries?category_id=${categoryId}`,
          { headers: { lang: state.lang } }
        );

        if (response.data.status === true) {
          commit('setCountryMap', { categoryId, countries: response.data.data });
        }
      } catch (error) {
        commit('setError', 'Server error, try again later.');
        console.error('Error fetching countries:', error);
      } finally {
        commit('setCountryLoading', { categoryId, value: false });
      }
    }
  }
});

export default store;
