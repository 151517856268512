<template>
    <div v-if="isLoading">
        <div class="loading-spinner"></div>
    </div>
    <div v-else>
        <div class="category_wrapper">
            <div class="container products" style="padding-bottom: 2rem !important"
                v-if="products && products.length > 0">
                <!-- Add Heading for Products -->
              <div class="category_heading">Latest Games</div>
                <swiper :modules="modules" :slides-per-view="5" :space-between="40" :navigation="true"
                    :pagination="{ clickable: true }" :draggable="true" :keyboard="{ enabled: true }"
                    :breakpoints="{
                        320: {
                            slidesPerView: 'auto',
                            centeredSlides: true,
                            spaceBetween: 20
                        },
                        480: {
                            slidesPerView: 'auto',
                            centeredSlides: true,
                            spaceBetween: 20
                        },
                        768: {
                            slidesPerView: 3,
                            centeredSlides: false,
                            spaceBetween: 30
                        },
                        1024: {
                            slidesPerView: 4,
                            centeredSlides: false,
                            spaceBetween: 30
                        },
                        1200: {
                            slidesPerView: 5,
                            centeredSlides: false,
                            spaceBetween: 40
                        }
                    }"
                     @slideChange="onSlideChange">
                    <swiper-slide class="card_wrapper" v-for="item in products" :key="item.id">
                        <div style="display: flex; flex-direction: column; gap: .5rem">
                            <a style="text-decoration: none !important"
                                :href="`/card/${item.id}/${replaceSpaces(item.name)}`">
                                <div class="prod-image">
                                    <img :src="item.img" :alt="item.name" />
                                    <p>{{ item.sub_category.name }}</p>
                                    <h4 class="prod-name">
                                        {{ item.name.length > 39 ? item.name.slice(0, 39) + "..." : item.name }}
                                        <div class="hint-pop-up">
                                            {{ item.name }}
                                        </div>
                                    </h4>
                                </div>
                                <div>
                                    <div class="region_icon_wrapper">
                                        <div class="region_icon_wrapper_flag">
                                            <img :src="item.country.flag" :alt="item.country.name" class="region_icon"
                                                v-if="item.country" />
                                        </div>
                                        <span class="region_icon_wrapper_country_name">{{
                                            item.country.name
                                            }}</span>
                                    </div>
                                    <div>
                                        <div class="price">
                                            <h1 v-if="item.price_after_discount">
                                                {{
                                                    item.price_after_discount
                                                        ? item.price_after_discount.toLocaleString()
                                                        : ""
                                                }}
                                            </h1>
                                            <h1>{{ item.price.toLocaleString() }}</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <button class="add-to-cart" :class="{ 'out-of-stock-prod': !isProductInStock(item) }"
                                @click="addCardToCart(item, 1)">
                              {{ item.type == 0 ? (lang == 'en' ? "In Stock" : "متاح")  : (isProductInStock(item)  ? (lang == 'en' ? "Limited Stock" : "كمية محدودة") : (lang == 'en' ? "Out Of Stock" : "نفذت الكمية")) }}
                            </button>
                            <button :class="item.isFav ? 'active' : ''" class="add-to-wishlist"
                                @click="likeProduct(item.id)">
                                <i class="fa-regular fa-heart icon-size" width="20" height="20"></i>
                                {{ lang == "en" ? "Add To Wishlist" : "اضافة الي المفضلة" }}
                            </button>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <!-- !-- Add pagination controls -->
        <!-- <div class="pagination" v-if="totalPages > 1">
            <button :disabled="currentPage === 1" @click="fetchProducts(currentPage - 1)">
                Previous
            </button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button :disabled="currentPage === totalPages" @click="fetchProducts(currentPage + 1)">
                Next
            </button>
        </div> -->
    </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
    name: "ProductCard",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Pagination],
        };
    },
    data() {
        return {
            lang: "en",
            products: [],
            currentPage: 1,
            totalPages: 1,
            perPage: 10,
            isLoading: false,
            displayCount: 5,
            maxCardsToShow: 20,
        };
    },
    created() {
        this.fetchProducts();
    },
    methods: {
        /**
         * Fetch products from the API
         * @param {number} page - The page number to fetch
         */
        async fetchProducts(page = 1) {
            try {
                this.isLoading = true;
                const response = await axios.get(
                    `${API_URL}/products/home`,
                    {
                        params: {
                            per_page: this.perPage,
                            page: page,
                        },
                    }
                );

                if (response.data.status) {
                    const data = response.data.data;
                    if (page === 1) {
                        this.products = data.cards;
                    } else {
                        this.products = [...this.products, ...data.cards].slice(-this.maxCardsToShow);
                    }
                    this.currentPage = data.current_page;
                    this.totalPages = data.last_page;
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                this.isLoading = false;
            }
        },

        /**
         * Handles the swiper slide change event
         * Loads more products when user reaches the end of the carousel
         * @param {Object} swiper - The Swiper instance
         * @param {boolean} swiper.isEnd - Whether the last slide is active
         * @fires fetchProducts
         */
        onSlideChange(swiper) {
            if (swiper.isEnd && this.currentPage < this.totalPages) {
                this.fetchProducts(this.currentPage + 1);
            }
        },

        /**
         * Add a product to the cart
         * @param {Object} product - The product item
         * @param {number} qty - The quantity to add
         * @param {number} product_valid_qty - The valid quantity of the product
         * @param {number} product_stock - The stock of the product
         */
        async addCardToCart(product, qty) {
            if (!this.isProductInStock(product)) {
                return;
            }
            $(".loader").fadeIn().css("display", "flex");
            try {
                const response = await axios.post(
                    `${API_URL}/cards/${product.id}/add-cart`,
                    {
                        qty: qty,
                        type: "add",
                    },
                    {
                        headers: {
                            AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
                            lang: this.lang,
                        },
                    }
                );
                if (response.data.status === true) {
                    document.getElementById("errors").innerHTML = "";
                    let error = document.createElement("div");
                    error.classList = "success";
                    error.innerHTML = response.data.message;
                    document.getElementById("errors").append(error);
                    $("#errors").fadeIn("slow");
                    setTimeout(() => {
                        $("#errors").fadeOut("slow");
                        $(".loader").fadeOut();
                        if (!this.cart || !this.cart.length) {
                            window.location.reload();
                        }
                    }, 1000);
                } else {
                    $(".loader").fadeOut();
                    document.getElementById("errors").innerHTML = "";
                    let error = document.createElement("div");
                    error.classList = "error";
                    error.innerHTML =
                        response.data.errors[0] == "quantity is not available" ||
                            response.data.errors[0] == "الكمية المطلوبة غير متوفرة"
                            ? this.lang == "ar"
                                ? "نفذت الكمية"
                                : "Quantity not avilable"
                            : this.lang == "ar"
                                ? "يجب عليك تسجيل الدخول اولا"
                                : "You have to login first!";
                    document.getElementById("errors").append(error);
                    $("#errors").fadeIn("slow");

                    setTimeout(() => {
                        $("input").css("outline", "none");
                        $("#errors").fadeOut("slow");
                    }, 3500);
                }
            } catch (error) {
                document.getElementById("errors").innerHTML = "";
                let err = document.createElement("div");
                err.classList = "error";
                err.innerHTML = "server error try again later";
                document.getElementById("errors").append(err);
                $("#errors").fadeIn("slow");
                $(".loader").fadeOut();

                setTimeout(() => {
                    $("#errors").fadeOut("slow");
                }, 3500);

                console.error(error);
            }
        },

        /**
         * Navigate to product detail page
         * @param { Object } product - The product to view
         */
        goToProductDetail(product) {
            const formattedName = this.replaceSpaces(product.name);
            this.$router.push({
                path: `/product/${product.id}/${formattedName}`,
            });
        },
        /**
         * Like a product
         * @param {number} product_id - The product ID
         */
        async likeProduct(product_id) {
            try {
                const response = await axios.post(
                    `${API_URL}/cards/${product_id}/liked`,
                    {},
                    {
                        headers: {
                            AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
                            lang: this.lang,
                        },
                    }
                );
                if (response.data.status === true) {
                    document.getElementById("errors").innerHTML = "";
                    $(".loader").fadeOut();
                    this.fetchProducts(this.categoryId);
                } else {
                    $(".loader").fadeOut();
                    document.getElementById("errors").innerHTML = "";
                    $.each(response.data.errors, function (key, value) {
                        let error = document.createElement("div");
                        error.classList = "error";
                        error.innerHTML = value;
                        document.getElementById("errors").append(error);
                    });
                    $("#errors").fadeIn("slow");

                    setTimeout(() => {
                        $("input").css("outline", "none");
                        $("#errors").fadeOut("slow");
                    }, 3500);
                }
            } catch (error) {
                document.getElementById("errors").innerHTML = "";
                let err = document.createElement("div");
                err.classList = "error";
                err.innerHTML = "server error try again later";
                document.getElementById("errors").append(err);
                $("#errors").fadeIn("slow");
                $(".loader").fadeOut();

                setTimeout(() => {
                    $("#errors").fadeOut("slow");
                }, 3500);

                console.error(error);
            }
        },
    },
};
</script>

<style scoped>
@import "./../assets/css/home.css";


.card_wrapper {
    max-width: 400px;
    border: 1px solid #d5dfe4;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .7rem;
    position: relative;
}

.prod-image>>>img {
    width: 100%;
    height: 170px;
    -o-object-fit: contain;
    object-fit: contain;
}

.card_wrapper p {
    font-size: .9rem;
    color: #717171;
}

.prod-name {
    font-size: 1.1rem;
    font-weight: 500;
    color: #0473b0;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price>>>h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0b5177;
}


.add-to-cart {
    padding: .5rem;
    font-size: .8rem;
    font-weight: 600;
    color: #435ca1;
    background: transparent;
    border: 1px solid #425a8c;
    border-radius: .3rem;
    transition: color .3s ease-in;
    cursor: pointer;
}

.add-to-cart:hover {
    color: #fff;
    background-color: #425a8c;
}

.out-of-stock-prod {
    color: #f34135 !important;
    border: 1px solid #f34135 !important;
}

.out-of-stock-prod:hover {
    background: #f34135 !important;
    color: white !important;
}

.add-to-wishlist {
    font-size: .9rem;
    font-weight: 400;
    color: #597b94;
    background: transparent;
    border: none;
    border-radius: .3rem;
    display: flex;
    gap: .7rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    transition: color .3s ease-in;
    cursor: pointer;
}

.icon-size {
    font-size: 20px;
}

.category_heading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: clamp(1.25rem, calc(0.7955rem + 1.8182vw), 2.5rem);
    font-weight: 400;
    letter-spacing: 1px;
}

.pagination {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem !important;
    margin-bottom: 2rem !important;
}

.pagination button {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.pagination span {
    font-size: 0.9rem;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-spinner::after {
    content: "";
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.region_icon_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.region_icon_wrapper_flag {
    height: 20px;
    width: 20px;
}

.region_icon {
    height: 100%;
    width: 100%;
}

.region_icon_wrapper_country_name {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

/* Add Swiper custom styles */
:deep(.swiper) {
    padding: 20px 0;
}

:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
    color: #007bff;
}

:deep(.swiper-pagination-bullet-active) {
    background: #007bff;
}

:deep(.swiper-pagination) {
    margin-top: 20px;
    position: relative;
    bottom: 0;
}

:deep(.swiper-slide) {
    width: 90%;  /* Allow slides to size based on content */
    max-width: 90%;  /* Prevent slides from being too wide on mobile */
}

@media (min-width: 768px) {
    :deep(.swiper-slide) {
        width: auto;
        max-width: 400px;
    }
}

@media (max-width: 320px ){
    :deep(.swiper-slide) {
    width: 80%;  /* Allow slides to size based on content */
    max-width: 80%;  /* Prevent slides from being too wide on mobile */
}
}
</style>