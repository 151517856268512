<template>
    <footer>
        <div class="container">
            <div class="main">
                <div class="bg">
                    <div class="container">
                        <span class="circle"></span>
                    </div>
                    <span class="square"></span>
                </div>
                <div class="logo-container">
                    <div class="com-help-container">
                        <h3>Community</h3>
                        <router-link to="/news">News Page</router-link>
                        <router-link to="/careers">{{ lang == 'en' ? "Careers" : "وظائف" }}</router-link>
                        <router-link to="/student_discount">Student Discount</router-link>
                        <router-link to="/affiliate">Affiliate</router-link>

                    </div>
                    <img src="./../assets/imgs/logo-dark.png" alt="main site logo">
                    <div class="com-help-container">
                        <h3>Help</h3>
                        <router-link to="/faq">FAQ</router-link>
                        <router-link to="/rewards">Rewards program</router-link>
                        <router-link to="/product_activation">Activation guide</router-link>
                        <router-link to="/contact-us">Contact Us</router-link>


                    </div>
                </div>
                <div class="call-container">
                    <div class="trustpilot-container">
                        <!-- TrustBox widget - Review Collector -->
                        <!-- <div class="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6692c197940a502fa45f2280" data-style-height="52px" data-style-width="100%"> -->
                        <a href="https://www.trustpilot.com/review/egyptgamestore.com" target="_blank" rel="noopener">Review us on <i class="fa-solid fa-star active trustpilot-icon"></i> Trustpilot</a>
                        <!-- </div> -->
                        <!-- End TrustBox widget -->
                    </div>
                    <div class="call">
                        <img src="./../assets/imgs/customer_icon.png" alt="call logo">
                        <p>
                            {{ footer_data.Got_questions}} {{footer_data.Call}}!
                            <router-link to="tel:01145636999">01145636999</router-link>
                        </p>
                    </div>

                    <div class="trustpilot-container follow-us">
                        Follow us
                        <a v-if="socialLinks.facebook_link" :href="socialLinks.facebook_link" target="_blank">
                            <i class="fa-brands fa-square-facebook"></i>
                        </a>
                        <a v-if="socialLinks.x_link" :href="socialLinks.x_link" target="_blank">
                            <img src="../assets/imgs/twitter.svg" class="twitter-icon" height="30" width="30" alt="X icon" srcset="">
                        </a>
                        <a v-if="socialLinks.instagram_link" :href="socialLinks.instagram_link" target="_blank">
                            <i class="fa-brands fa-square-instagram"></i>
                        </a>
                        <a v-if="socialLinks.youtube_link" :href="socialLinks.youtube_link" target="_blank">
                            <i class="fa-brands fa-square-youtube"></i>
                        </a>
                    </div>

                </div>
                <img src="./../assets/imgs/banks.png" alt="banks detail">
            </div>
        </div>
        <div class="copy">
            {{ footer_data.cpr }}
        </div>
    </footer>
</template>

<script>
export default {
    name: 'MainFooter',
    props:{
        socialLinks: {
            required: false,
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            footer_data: null,
            lang: "en"
        }
    },
    methods: {
        setLangCookies() {
            let langCheck = document.cookie.indexOf('lang')

            this.is_cookies = langCheck >= 0 ? true : false

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            } // to get an cookie by name ##############################

            if (langCheck !== -1) {
                this.lang = getCookie('lang') // check lang cookie exist ##############################
            }

            if (sessionStorage.getItem("lang"))
                this.lang = sessionStorage.getItem("lang") // check lang session exist ##############################

            sessionStorage.setItem("lang", this.lang); // set lang session ##############################

            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('lang')) {
                this.lang = searchParams.get('lang')
                document.body.classList.add(searchParams.get('lang')) // add lang class ##############################
            } else {
                document.body.classList.add(this.lang) // add lang class ##############################
            }

        },
        getHomeData() {
            this.setLangCookies()
            let data = require('../assets/api/footer.json');
            this.footer_data = this.lang == 'ar' ? data.ar : data.en
        },

    },
    created() {
        this.getHomeData()
    },
}
</script>
<style scoped>
.call-container{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
}

.trustpilot-container{
    border-left: 3px solid #0D4769;
    border-right: 5px solid #0D4769;
    border-bottom: 4px solid #0D4769;
    height: 50px;
    display: flex;
    align-items: center;
    background: white;
    padding: 8px;
}
.trustpilot-container a{
    text-decoration: none;
    color: black;
}
.trustpilot-widget a {
    text-decoration: none;
    color: black;
}
.trustpilot-icon{
    color: #04DA8D;
}
.follow-us{
    display: flex;
    gap: 6px;
}
.follow-us i {
    font-size: 30px;
}
.follow-us a{
    text-decoration: none;
    color: black;
}
.twitter-icon{
    margin-top: 4px;
    cursor: pointer;
}
.com-help-container{
    display: flex;
    flex-direction: column;

}
.logo-container{
    display: flex;
    align-items: flex-end;
    gap: 4rem;
    flex-wrap: wrap;
    color: #0F3CA1;
    justify-content: center;
}
.logo-container a{
    color: #0F3CA1;
    text-decoration: none;
}

@media (max-width: 690px) {
    .logo-container, .call-container{
        flex-direction: column;
        align-items: center;
    }
}
</style>